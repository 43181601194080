import React, { ReactNode } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import cx from "classnames";
import moment from "moment";

import Wrapper from "./Wrapper";
import "./__styles__/Datepicker.scss";
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
} from "react-hook-form";

const parseValue = (value: Maybe<string | Date>) => {
  if (!value) return null;

  if (value instanceof Date) return value;

  return moment(moment.utc(value).format("YYYY-MM-DD")).toDate();
};

function toString(val: Maybe<Date>) {
  if (!val) return "";
  const date = moment.utc(val);
  return date.format("YYYY-MM-DD");
}

type DatepickerProps = {
  label?: string;
  compactLabel?: boolean;
  name: string;
  value: Maybe<Date | string>;
  error?: string;
  onChange: (value: string) => void;
  size?: "smaller" | "small" | "medium" | "large";
  children?: ReactNode;
  placeholderText?: string;
  disabled?: boolean;
  required?: boolean;
  description?: Maybe<string>;
  minDate?: Maybe<Date>;
  maxDate?: Maybe<Date>;
} & Omit<ReactDatePickerProps, "onChange" | "value">;

const Datepicker = ({
  label,
  compactLabel = false,
  name,
  value,
  error,
  onChange,
  size = "medium",
  children,
  required,
  description,
  minDate,
  maxDate,
  ...props
}: DatepickerProps) => {
  return (
    <Wrapper
      label={label}
      compactLabel={compactLabel}
      name={name}
      error={error}
      addon={children}
      required={required}
      description={description}
      labelTabIndex={-1}
    >
      <DatePicker
        selected={parseValue(value)}
        onChange={date => onChange(toString(date as Maybe<Date>))}
        styleName={cx("input", size, { error: !!error })}
        id={name}
        maxDate={maxDate ?? new Date("12/31/9999")}
        // The default is effectively no minimum; however, the date picker doesn't like it when I set it to the year 0
        minDate={minDate ?? new Date("01/01/1000")}
        {...props}
      />
    </Wrapper>
  );
};

export default Datepicker;

type ReactHookFormDatepickerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Pick<ControllerProps<TFieldValues, TName>, "control" | "name" | "rules"> &
  Omit<DatepickerProps, "onChange" | "value"> & {
    onChange?: (value: Maybe<Date>) => void;
  };

export const ReactHookFormDatepicker = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name,
  rules,
  required = false,
  onChange,
  ...props
}: ReactHookFormDatepickerProps<TFieldValues, TName>) => {
  return (
    <Controller<TFieldValues, TName>
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => (
        <Datepicker
          name={name}
          value={field.value!}
          error={fieldState.error?.message}
          required={required}
          {...props}
          onChange={value => {
            field.onChange(value);
            onChange?.(new Date(value));
          }}
        />
      )}
    />
  );
};
