import React from "react";
import { Icon } from "../Common/Icons/LucideIcons";
import Tooltip from "../Common/Tooltip";

import {
  Required,
  Description,
  Label,
  TextWrapper,
  TooltipText,
} from "./__styles__/Label";
import { ExtractVariants } from "../../stitches.config";

export type LabelProps = {
  text: string;
  required?: boolean;
  description?: Maybe<string>;
  tabIndex?: number;
  htmlFor?: string;
  disabled?: boolean;
  tooltip?: string;
  style?: React.CSSProperties;
} & ExtractVariants<typeof Label>;

export default ({
  text,
  required = false,
  description,
  tooltip,
  tabIndex = 0,
  disabled = false,
  ...props
}: LabelProps) => (
  <Label tabIndex={tabIndex} {...props}>
    <TextWrapper disabled={disabled}>{text}</TextWrapper>
    {required && <Required>*</Required>}
    {description && (
      <Description disabled={disabled}>{description}</Description>
    )}
    {tooltip && <LabelTooltip text={tooltip} />}
  </Label>
);

const LabelTooltip = ({ text }: { text: string }) => {
  return (
    <Tooltip
      content={
        <span
          style={{
            marginTop: "3px",
            marginLeft: "4px",
            display: "inline-block",
          }}
        >
          <Icon iconName="info" color="grey4" size={16} cursor="pointer" />
        </span>
      }
      popoverContent={<TooltipText>{text}</TooltipText>}
      place="right"
    />
  );
};
