import React, { ChangeEvent, InputHTMLAttributes } from "react";

import "./__styles__/Checkbox.scss";

interface Props {
  value?: boolean;
  name?: string;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

export default ({ value = false, name, onChange, disabled = false }: Props) => {
  const _handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    onChange(evt.target.checked);
  };

  return (
    <input
      type="checkbox"
      data-testid="checkbox"
      styleName="input"
      checked={!!value}
      onChange={_handleChange}
      disabled={disabled}
      id={name}
    />
  );
};

export const ReactHookFormCheckbox = React.forwardRef(
  (
    props: InputHTMLAttributes<HTMLInputElement>,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <input
        type="checkbox"
        data-testid="checkbox"
        styleName="input"
        ref={ref}
        {...props}
      />
    );
  }
);
