import React, { TextareaHTMLAttributes } from "react";
import ReactTextarea from "react-textarea-autosize";
import cx from "classnames";

import Wrapper from "./Wrapper";
import "./__styles__/Textarea.scss";
import { ChangeHandler } from "react-hook-form";

type StrippedDownInputProps = Omit<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  "size" | "value" | "onChange"
>;

interface Props extends StrippedDownInputProps {
  name: string;
  value?: string | number | null;
  label?: Maybe<string>;
  compactLabel?: boolean;
  size?: "small" | "extra-small" | null;
  error?: string;
  styleOverride?: any;
  placeholder?: string;
  minRows?: number;
  required?: boolean;
  description?: Maybe<string>;
}

const Textarea = ({
  name,
  value = null,
  onChange,
  label = null,
  compactLabel = false,
  size = null,
  error,
  styleOverride = null,
  placeholder,
  minRows = 5,
  required,
  description,
}: Props & {
  onChange?: (value: string) => void;
}) => (
  <Wrapper
    label={label}
    compactLabel={compactLabel}
    name={name}
    error={error}
    required={required}
    description={description}
  >
    <ReactTextarea
      name={name}
      onChange={evt => onChange?.(evt.target.value)}
      styleName={cx(size, "input", {
        error: !!error,
      })}
      style={styleOverride}
      id={name}
      value={value?.toString() ?? ""}
      placeholder={placeholder}
      minRows={minRows}
      maxRows={15}
    />
  </Wrapper>
);

export const ReactHookFormTextareaInput = React.forwardRef(
  (
    {
      name,
      onChange,
      label = null,
      compactLabel = false,
      size = null,
      error,
      styleOverride = null,
      placeholder,
      minRows = 5,
      ...props
    }: Omit<Props, "value"> & { onChange?: ChangeHandler },
    ref: React.Ref<HTMLTextAreaElement>
  ) => {
    return (
      <Wrapper
        label={label}
        compactLabel={compactLabel}
        name={name}
        error={error}
        labelTabIndex={-1}
      >
        <ReactTextarea
          name={name}
          onChange={onChange}
          styleName={cx(size, "input", {
            error: !!error,
          })}
          style={styleOverride}
          id={name}
          placeholder={placeholder}
          minRows={minRows}
          maxRows={15}
          inputRef={ref as React.RefObject<HTMLTextAreaElement>}
          {...props}
        />
      </Wrapper>
    );
  }
);

export default Textarea;
